import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDizzy } from '@fortawesome/free-solid-svg-icons';

import Logo from '../Login/Logo';

const MaintenancePage = () => (
  <div className="bg-content-positive flex min-h-screen w-full">
    <div className="mb-16 flex flex-1 flex-col items-center justify-center">
      <div className="mb-16 w-full">
        <Logo />
      </div>
      <div className="mt-13 mx-auto w-full text-center opacity-90">
        {[...Array(3).keys()].map((index) => (
          <FontAwesomeIcon
            icon={faDizzy}
            size="4x"
            className="text-negative-subtle mx-3"
            key={index}
          />
        ))}
      </div>
      <div className="mt-12 w-full px-2 text-center text-2xl text-white md:text-4xl">
        Freddie is currently down for maintenance.
      </div>
      <div className="mt-4 w-full px-2 text-center text-sm text-white md:text-xl">
        We will be back in a couple hours and thank you for your patience
      </div>
    </div>
  </div>
);

export default MaintenancePage;
