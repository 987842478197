import { ApolloProvider } from '@apollo/client';
import {
  API_HOST,
  BRAND_NAME,
  ENVIRONMENT,
  LAUNCH_DARKLY_SDK_KEY,
  sentryConfigs,
  SharedAppProviders,
  TooltipProvider,
} from '@pm/core';
import { Toaster as ToasterWrapper } from '@pm/ui';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import { ComponentProps, StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';
import App from './apps';
import SEO from './utilities/seo';
import { apolloClient } from './api/client';
import '@material-symbols/font-500/rounded.css';

// translations
import './localization/i18n';

// css files
import 'tailwindcss/tailwind.css';
import '@pm/ui/themes/global.css';
import '@pm/ui/themes/purposemed.css';

console.log('Environment variables:', ENVIRONMENT);
const isProdEnv = ENVIRONMENT === 'production';

// initialize our Sentry crash reporting tool
if (import.meta.env.PROD) {
  const sentryEnvironment = isProdEnv ? 'prod' : 'dev';

  Sentry.init({
    dsn: 'https://960b74e596904b62aea0e3cf30e78c46@o371410.ingest.sentry.io/6109123',
    environment: sentryEnvironment,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: sentryEnvironment === 'prod' ? 0.1 : 1.0,
    tracePropagationTargets: [
      'https://api.purposemed.com/graphql',
      'https://api-staging.gofreddie.com/graphql',
    ],
    ...sentryConfigs,
  });
}

const ENV_OPTIONS: Omit<
  ComponentProps<typeof SharedAppProviders>,
  'children'
> = {
  brand: BRAND_NAME.PurposeMed,
  apiHost: API_HOST,
  environment: ENVIRONMENT,
  launchDarklySDKKey: LAUNCH_DARKLY_SDK_KEY,
  formattingContext: 'provider',
};

createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <BrowserRouter>
      <HelmetProvider>
        <TooltipProvider>
          <Suspense fallback={null}>
            <ApolloProvider client={apolloClient}>
              <SharedAppProviders {...ENV_OPTIONS}>
                <GoogleOAuthProvider clientId="103733511066-epjcoah3do7ag8v5ms54f994ik1kcads.apps.googleusercontent.com">
                  <Helmet
                    titleTemplate="%s | PurposeMed"
                    defaultTitle="PurposeMed"
                  />
                  <ToasterWrapper>
                    <App />
                  </ToasterWrapper>
                  <SEO />
                  <Toaster position="top-right" />
                </GoogleOAuthProvider>
              </SharedAppProviders>
            </ApolloProvider>
          </Suspense>
        </TooltipProvider>
      </HelmetProvider>
    </BrowserRouter>
  </StrictMode>,
);
